.body {
  background: #f8f5f5 ;
  color: #000000;
}

.trainboard-app > .row{
  overflow-x: auto;
  flex-wrap: wrap;
}

.trainsheading {
  padding: 5px;
  text-align: center;
  color: #318d91;
}